/* Playlist */
/* Videos view */
/* .watch-video-item:first-child {
  animation: var(--ct-animation-expand-padding);
  -o-animation: var(--ct-animation-expand-padding);
  -moz-animation: var(--ct-animation-expand-padding);
  -webkit-animation: var(--ct-animation-expand-padding);
} */
/* .watch-playlists-menu {
    padding-top: 130px;
  } */
.watch-playlists-menu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0em 10vw 0 10vw;
  overflow-x: auto;
  overflow-y: auto;
  animation: var(--ct-animation-popup);
  -o-animation: var(--ct-animation-popup);
  -moz-animation: var(--ct-animation-popup);
  -webkit-animation: var(--ct-animation-popup);
}
.watch-playlists-menu-close-btn {
  position: absolute;
  right: 5em;
  top: 10px;
  z-index: 2;
}
.watch-list-title {
  position: sticky;
  top: 0;
  font-weight: bold;
  font-size: 18px;
  z-index: 1;
  width: 100%;
  padding: 15px 10px;
  margin-bottom: 4px;
  background-color: var(--watch-page-bg-color-alpha);
  display: flex;
  align-items: center;
  i {
    margin-right: .5em;
  }
}
.watch-playlists-list {
  position: sticky;
  top: 0;
  width: 40vw;
  max-width: 30em;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1em;
}
.watch-playlist-item {
  position: relative;
  color: var(--ct-text-white);
  width: 100% !important;
  height: max-content !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  outline: none;
  min-height: 5em;
  transition: var(--ct-transition-color);
  -o-transition: var(--ct-transition-color);
  -moz-transition: var(--ct-transition-color);
  -webkit-transition: var(--ct-transition-color);
  &:hover {
    color: var(--ct-text-white-hover);
  }
  &:focus {
    color: var(--ct-green-active);
    &:hover {
      color: var(--ct-green-active);
    }
  }
  .library-icon {
    margin-right: 10px;
    margin-left: 10px;
    width: 25px;
    overflow: hidden;
  }
  .playlist-name {
    height: 4em;
    display: flex;
    width: calc(100% - 45px);
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: var(--ct-border-dark);
    font-weight: bold;
    font-size: 15px;
    padding: 0 !important;
    margin: 0 !important;
    span {
      font-weight: normal;
      font-size: 12px;
      color: var(--ct-green-active);
    }
  }
  .right-arrow {
    position: absolute;
    right: 2px;
  }
  &:last-child {
    .playlist-name {
      border-bottom: var(--ct-border-color-transparent);
    }
  }
}
.watch-playlist-item[active=true] {
  &:focus {
    color: var(--ct-green-active);
  }
  color: var(--ct-text-highlight);
}
.watch-videos-list {
  position: relative;
  width: 35em;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.watch-video-item {
  display: block;
  margin: 0;
  width: 100%;
  border-bottom: var(--ct-border-dark) !important;
  &:last-child {
    border-bottom: none !important;
  }
}
@media screen and (max-width: 700px) {
  .watch-playlists-list {
    display: none;
  }
}
.playlist-item {
  display: flex;
  white-space: nowrap !important;
  overflow: hidden; 
}
#inote-button {
  color: white !important;
  &:hover {
    color: teal !important;
  }
}
